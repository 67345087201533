export  const  OTPREMNICA = 1;
export  const  PROKNJIZENA_OTPREMNICA = 2;
export  const  FAKTURA = 3;
export const  PAKOVANJE = 4;

export const STATUS_LABEL = {
    1: 'Otpremnica',
    2: 'Proknjižena otpremnica',
    3: 'Faktura',
    4: 'Pakovanje',
}
