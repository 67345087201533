import { Component } from "react";

import { FormGroup, Input, Label, FormFeedback } from 'reactstrap';

class TextareaField extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <FormGroup className="position-relative">
                <Label>{this.props.label}</Label>
                <textarea className={`${this.props.touched && this.props.error ? 'is-invalid' : this.props.touched ? 'is-valid' : ''} form-control`} 
                    ref={(node) => this.props.setFieldRef(node)}
                    onKeyUp={(e) => {
                        if (e.key == this.props.finishKey) {
                            this.props.focusNext();
                        }
                    }}
                    onChange={(e) => this.props.onChange(e.target.value)}
                    value={this.props.value ? this.props.value : ''}
                    placeholder={this.props.placeholder}
                    cols="10" rows="3"
                ></textarea>
                {this.props.error && this.props.touched ?
                    <FormFeedback invalid tooltip>{this.props.error}</FormFeedback>
                    :

                    null
                }

            </FormGroup>
        )
    }
}

export default TextareaField;
