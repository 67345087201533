import { Component } from "react";

import { FormGroup, Input, Label, FormFeedback } from 'reactstrap';

class TextTransparent extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <FormGroup className="position-relative input-transparent-container">
                <Label>{this.props.label}</Label>
                <input className={`${this.props.touched && this.props.error ? 'is-invalid' : this.props.touched ? 'is-valid' : ''} form-control`} type="text"
                    ref={(node) => this.props.setFieldRef(node)}
                    onKeyUp={(e) => {
                        if (e.key == this.props.finishKey) {
                            this.props.focusNext();
                        }
                    }}
                    onChange={(e) => {
                        // if(!this.props.disabled){
                            this.props.onChange(e.target.value)
                        // }
                    }}
                    value={this.props.value ? this.props.value : ''}
                    placeholder={this.props.placeholder}
                ></input>
                {this.props.error && this.props.touched ?
                    <FormFeedback invalid tooltip>{this.props.error}</FormFeedback>
                    :

                    null
                }

            </FormGroup>
        )
    }
}

export default TextTransparent;
