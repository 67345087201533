import React from 'react';
import { jsPDF } from "jspdf";

import {
    Button
} from 'reactstrap';

import Isvg from 'react-inlinesvg';

/**
* Open print page of browser
* @author   Milan Stanojevic
*/
class PrintHelper extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {

        if (this.props.html) {
            const doc = new jsPDF('p', 'mm', [210, 297]);
            doc.html(this.props.html, {
                margin: [20, 20, 20, 20],

                html2canvas: {
                    scale: 0.1909
                },

                callback: () => {

                    this.setState({
                        blob: doc.output('bloburl')
                    })
                },

            })
        }
    }
    componentDidUpdate(prevProps, prevState){
        if(this.state.blob){
            if(prevState._loaded != this.state._loaded && this.state._loaded){
                this.iframe.focus();
                this.iframe.contentWindow.print()
            }
        }
        if(prevState.blob != this.state.blob){
            if(this.state._loaded){
                this.iframe.focus();
                this.iframe.contentWindow.print()
            }
        }
        
    }
    componentWillUnmount(){
    }

    render() {
        return (
            <div style={{ width: 0, height: 0}}>

                <iframe width="0" height="0" style={{ border: 0 }} src={this.state.blob} ref={(node) => this.iframe = node} onLoad={() => this.setState({ _loaded: true })}></iframe>

            </div>
        )
    }
}

export default PrintHelper;